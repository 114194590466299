import React from "react"
import PropTypes from "prop-types"


import { Container, HeaderWrapper } from "../../global"
import Layout from "./layout"

const PageLayout = ({ children }) => (
  <Layout>
    <HeaderWrapper id="top">
      <Container>
        {children}
      </Container>
    </HeaderWrapper>
  </Layout>
);

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout;
