import React from 'react';
import SEO from "../components/common/layout/seo"
import PageLayout from "../components/common/layout/pageLayout";

const Pricing = () => {
  return (
    <PageLayout>
      <SEO title="Pricing" />
      <h1>Pricing</h1>
      <div>Coming soon</div>
    </PageLayout>

  );
}

export default Pricing;
